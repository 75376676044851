<template>
  <CommonLayout class="page_fullscreen">
    <div class="content">
      <h2>401</h2>
      <h1>Доступ запрещен</h1>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonLayout,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    isAuthenticated(state) {
      if (state) {
        this.$router.push({ name: "home" });
      }
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 100px;
  padding-bottom: 200px;
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 400px;
    line-height: 463px;
    letter-spacing: 0.055em;
    color: #5cffed;
    margin: 0;
    margin-top: -130px;
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 70px;
    line-height: 81px;
    letter-spacing: 0.005em;
    color: #009382;
    margin: 0;
  }
}
</style>
